import React from "react";
import { Timed } from "react-time-sync";
import KitchenMonitor from "../containers/KitchenMonitor";

const KitchenMonitorTimer = () => {
  return (
    <Timed unit={60}>
      {({ currentTime }) => <KitchenMonitor currentTime={currentTime} />}
    </Timed>
  );
};

export default KitchenMonitorTimer;
