import gql from "graphql-tag";

const receiptFragment = gql`
  fragment ReceiptFragment on Receipt {
    __typename
    uuid
    created_at
    updated_at
    status
    pickup_code
    requested_pickup_time
    estimated_pickup_time
    table {
      table_type
    }
  }
`;

const getReceiptsQuery = gql`
  query getReceipts($params: ReceiptSearchParams!) {
    getReceipts(params: $params) {
      ...ReceiptFragment
    }
  }
  ${receiptFragment}
`;

const orderItemFragment = gql`
  fragment OrderItemFragment on OrderItem {
    __typename
    product_title
    product_price
    product_uuid
    comment
    table_uuid
    uuid
    modifierItems {
      __typename
      title
      price_modifier
      modifier_item_uuid
    }
    productGroup {
      title
      product_group_type
    }
  }
`;

const getExtendedReceiptsQuery = gql`
  query getReceipts($params: ReceiptSearchParams!) {
    getReceipts(params: $params) {
      ...ReceiptFragment
      orderItems {
        ...OrderItemFragment
      }
    }
  }
  ${receiptFragment}
  ${orderItemFragment}
`;

const updateReceiptMutation = gql`
  mutation adminUpdateReceipt($input: ReceiptUpdateInput!) {
    adminUpdateReceipt(input: $input) {
      ...ReceiptFragment
      orderItems {
        ...OrderItemFragment
      }
    }
  }
  ${receiptFragment}
  ${orderItemFragment}
`;

export { getReceiptsQuery, getExtendedReceiptsQuery, updateReceiptMutation };
