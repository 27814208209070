import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Receipt, OrderScreenConfig } from "models";
import { getsbyYellow } from "../helpers/Color";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(2) * 10,
    height: theme.spacing(2) * 8,
    padding: 0,
    margin: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none"
  }
}));

interface Props {
  receipt: Receipt;
  orderScreenConfig?: OrderScreenConfig;
}

const ReceiptCard = ({ receipt, orderScreenConfig }: Props) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={
        receipt.status === "ACCEPTED"
          ? {
              backgroundColor: orderScreenConfig
                ? orderScreenConfig.acceptedCardColor || getsbyYellow[500]
                : getsbyYellow[500],
              color: orderScreenConfig
                ? orderScreenConfig.acceptedFontColor || "inherit"
                : "inherit"
            }
          : {
              backgroundColor: orderScreenConfig
                ? orderScreenConfig.readyCardColor || "green"
                : "green",
              color: orderScreenConfig
                ? orderScreenConfig.readyFontColor || "white"
                : "white"
            }
      }
    >
      <CardContent style={{ padding: 0 }}>
        <Typography variant="h3" style={{ padding: 0 }}>
          {receipt.pickup_code}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReceiptCard;
