import React from "react";
import { Timed } from "react-time-sync";
import OrderScreen from "../containers/OrderScreen";
import { OrderScreenConfig } from "models";

interface Props {
  orderScreenConfig?: OrderScreenConfig;
}

const OrderScreenTimer = ({ orderScreenConfig }: Props) => {
  return (
    <Timed unit={60}>
      {({ currentTime }) => (
        <OrderScreen
          currentTime={currentTime}
          orderScreenConfig={orderScreenConfig}
        />
      )}
    </Timed>
  );
};

export default OrderScreenTimer;
