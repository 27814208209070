import { Color } from "@material-ui/core";

const getsbyYellow: Color = {
  50: "#F0C314",
  100: "#F0C314",
  200: "#F0C314",
  300: "#F0C314",
  400: "#F0C314",
  500: "#F0C314",
  600: "#F0C314",
  700: "#F0C314",
  800: "#F0C314",
  900: "#F0C314",
  A100: "#F0C314",
  A200: "#F0C314",
  A400: "#F0C314",
  A700: "#F0C314",
};

const getsbyGrey: Color = {
  50: "#232020",
  100: "#232020",
  200: "#232020",
  300: "#232020",
  400: "#232020",
  500: "#232020",
  600: "#232020",
  700: "#232020",
  800: "#232020",
  900: "#232020",
  A100: "#232020",
  A200: "#232020",
  A400: "#232020",
  A700: "#232020",
};

export { getsbyYellow, getsbyGrey };
