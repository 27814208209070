import React, { useEffect, useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getReceiptsQuery } from "../services/graphql/receipt";
import { Receipt, GraphQLResult, OrderScreenConfig } from "models";
import ReceiptCard from "../components/ReceiptCard";
import { Grid, Typography, makeStyles, Theme } from "@material-ui/core";
import { getsbyYellow } from "../helpers/Color";
import differenceInMinutes from "date-fns/differenceInMinutes";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  columnHeader: {
    textAlign: "center",
    borderRadius: "4px",
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  }
}));

interface Props {
  currentTime: number;
  orderScreenConfig?: OrderScreenConfig;
}

const OrderScreen = ({ currentTime, orderScreenConfig }: Props) => {
  const classes = useStyles();
  const [receipts, setReceipts] = useState<Receipt[]>([]);

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();

        const { data } = (await API.graphql(
          graphqlOperation(getReceiptsQuery, {
            params: {
              user_uuid: currentUser.getUsername(),
              status: ["ACCEPTED", "READY"],
              table_type: ["TAKEAWAY", "SELFSERVICE"]
            }
          })
        )) as GraphQLResult<{ getReceipts: Receipt[] }>;

        if (!data) {
          throw new Error("API Fehler!");
        }

        console.log("data", data.getReceipts);

        setReceipts(data.getReceipts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReceipts();
  }, [currentTime]);

  const filterAndSort = (
    receiptsToSort: Receipt[],
    status: "ACCEPTED" | "READY"
  ): Receipt[] => {
    return receiptsToSort
      .filter((r: Receipt) => {
        if (r.status === status) {
          if (status === "ACCEPTED" && r.estimated_pickup_time) {
            return (
              differenceInMinutes(
                new Date(r.estimated_pickup_time),
                new Date()
              ) < 30
            );
          } else if (status === "ACCEPTED" && r.requested_pickup_time) {
            return (
              differenceInMinutes(
                new Date(r.requested_pickup_time),
                new Date()
              ) < 30
            );
          }

          return true;
        } else {
          return false;
        }
      })
      .sort((a: Receipt, b: Receipt) => {
        if (a.pickup_code < b.pickup_code) {
          return -1;
        }
        if (a.pickup_code > b.pickup_code) {
          return 1;
        }
        return 0;
      });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <div
            className={classes.columnHeader}
            style={{
              backgroundColor: orderScreenConfig
                ? orderScreenConfig.acceptedCardColor || getsbyYellow[500]
                : getsbyYellow[500],
              color: orderScreenConfig
                ? orderScreenConfig.acceptedFontColor || "inherit"
                : "inherit"
            }}
          >
            <Typography variant="h3">In Bearbeitung</Typography>
          </div>
          <div className={classes.column}>
            {filterAndSort(receipts, "ACCEPTED").map((r: Receipt) => (
              <ReceiptCard
                receipt={r}
                key={r.uuid}
                orderScreenConfig={orderScreenConfig}
              />
            ))}
          </div>
        </Grid>
        <Grid item sm={6}>
          <div
            className={classes.columnHeader}
            style={{
              backgroundColor: orderScreenConfig
                ? orderScreenConfig.readyCardColor || "green"
                : "green",
              color: orderScreenConfig
                ? orderScreenConfig.readyFontColor || "white"
                : "white"
            }}
          >
            <Typography variant="h3">Abholbereit</Typography>
          </div>
          <div className={classes.column}>
            {filterAndSort(receipts, "READY").map((r: Receipt) => (
              <ReceiptCard
                receipt={r}
                key={r.uuid}
                orderScreenConfig={orderScreenConfig}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default OrderScreen;
