import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { ExtendedReceipt } from "models";
import ReceiptView from "./ReceiptView";
import { colors, Button, CardActionArea } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    width: 350,
    margin: theme.spacing(2)
  },
  actions: {
    padding: theme.spacing(2)
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

interface Props {
  receipt: ExtendedReceipt;
  onReady: (receipt: ExtendedReceipt) => void;
}

const KitchenMonitorCard = ({ receipt, onReady }: Props) => {
  const classes = useStyles();
  const [marked, setMarked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const isMarked = localStorage.getItem(`receipt_${receipt.uuid}`);

    if (isMarked) {
      setMarked(true);
    }
  }, [receipt.uuid]);

  const toggleMarker = () => {
    let isMarked = false;
    const item = localStorage.getItem(`receipt_${receipt.uuid}`);

    if (!item) {
      localStorage.setItem(`receipt_${receipt.uuid}`, "marked");
      isMarked = true;
    } else {
      localStorage.removeItem(`receipt_${receipt.uuid}`);
      isMarked = false;
    }

    setMarked(isMarked);
  };

  return (
    <Card
      className={classes.root}
      style={
        receipt.status === "ACCEPTED"
          ? marked
            ? { backgroundColor: colors.blue[100] }
            : { backgroundColor: colors.red[100] }
          : { backgroundColor: colors.green[100] }
      }
    >
      <CardActionArea>
        <CardContent onClick={toggleMarker}>
          <Typography variant="h3" style={{ textAlign: "center" }}>
            {receipt.pickup_code}
          </Typography>
          {receipt.estimated_pickup_time && (
            <Typography
              variant="h5"
              style={{ color: "red", textAlign: "center" }}
            >
              Fertig bis{" "}
              {new Date(receipt.estimated_pickup_time).toLocaleTimeString()}
            </Typography>
          )}
          {receipt.requested_pickup_time && (
            <Typography
              variant="h5"
              style={{ color: "red", textAlign: "center" }}
            >
              Fertig bis{" "}
              {new Date(receipt.requested_pickup_time).toLocaleTimeString()}
            </Typography>
          )}
          <ReceiptView receipt={receipt} />
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        {receipt.status === "ACCEPTED" && (
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              setLoading(true);
              onReady(receipt);
            }}
            style={{ width: "100%" }}
            disabled={loading}
          >
            Abholbereit
            {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
                color="secondary"
              />
            )}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default KitchenMonitorCard;
