import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Theme,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import OrderScreenTimer from "../components/OrderScreenTimer";
import Clock from "react-live-clock";
import MenuIcon from "@material-ui/icons/Menu";
import { Auth, graphqlOperation, API } from "aws-amplify";
import { getGastronomy } from "../services/graphql/gastronomy";
import { GraphQLResult, Gastronomy } from "models";
import { getsbyYellow } from "../helpers/Color";
import GetsbyLogo from "../components/GetsbyLogo";
import { Link, LinkProps, Route, Switch, useLocation } from "react-router-dom";
import KitchenMonitorTimer from "../components/KitchenMonitorTimer";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: "100%",
    padding: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  }
}));

const Layout = () => {
  const classes = useStyles();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [gastronomy, setGastronomy] = useState<Gastronomy | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getConfig = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();

        const { data } = (await API.graphql(
          graphqlOperation(getGastronomy, {
            uuid: currentUser.getUsername()
          })
        )) as GraphQLResult<{ getGastronomy: Gastronomy }>;

        if (!data) {
          throw new Error("API Fehler!");
        }

        console.log("data", data.getGastronomy);

        setGastronomy(data.getGastronomy);
      } catch (error) {
        console.error(error);
      }
    };

    getConfig();
  }, []);

  const OrderScreenLink = React.forwardRef<
    HTMLAnchorElement,
    Omit<LinkProps, "innerRef" | "to">
  >((props, ref) => <Link innerRef={ref as any} to="/" {...props} />);

  const KitchenMonitorLink = React.forwardRef<
    HTMLAnchorElement,
    Omit<LinkProps, "innerRef" | "to">
  >((props, ref) => (
    <Link innerRef={ref as any} to="/kitchen-monitor" {...props} />
  ));

  return (
    <React.Fragment>
      {location.pathname === "/" && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundBlendMode: "darken",
            backgroundColor: "rgba(0, 0, 0, .65)",
            backgroundImage:
              gastronomy &&
              gastronomy.orderScreenConfig &&
              gastronomy.orderScreenConfig.backgroundImageUrl
                ? `url(${gastronomy.orderScreenConfig.backgroundImageUrl})`
                : "",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            zIndex: -1
          }}
        ></div>
      )}
      <AppBar
        position="static"
        style={{
          backgroundColor:
            gastronomy &&
            gastronomy.orderScreenConfig &&
            gastronomy.orderScreenConfig.appBarColor
              ? gastronomy.orderScreenConfig.appBarColor || getsbyYellow[500]
              : getsbyYellow[500],
          color:
            gastronomy &&
            gastronomy.orderScreenConfig &&
            gastronomy.orderScreenConfig.appBarFontColor
              ? gastronomy.orderScreenConfig.appBarFontColor || "inherit"
              : "inherit"
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
          >
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  Auth.signOut();
                  setGastronomy(null);
                }}
              >
                Logout
              </MenuItem>
              <MenuItem component={OrderScreenLink} onClick={handleClose}>
                Abholung
              </MenuItem>
              <MenuItem component={KitchenMonitorLink} onClick={handleClose}>
                Küchenmonitor
              </MenuItem>
            </Menu>
            <GetsbyLogo
              color={
                gastronomy && gastronomy.orderScreenConfig
                  ? gastronomy.orderScreenConfig.appBarFontColor
                  : "rgb(23,20,20)"
              }
              height="40px"
              width="auto"
              maxWidth="65px"
            />
          </div>
          <Typography variant="h3" color="inherit" noWrap>
            Abholung
          </Typography>
          <Typography variant={"h3"}>
            <Clock format={"HH:mm:ss"} ticking={true} />
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Switch>
          <Route
            path="/"
            component={() => (
              <OrderScreenTimer
                orderScreenConfig={gastronomy?.orderScreenConfig}
              />
            )}
            exact
          />
          <Route
            path="/kitchen-monitor"
            component={() => <KitchenMonitorTimer />}
            exact
          />
          <Route
            component={() => (
              <OrderScreenTimer
                orderScreenConfig={gastronomy?.orderScreenConfig}
              />
            )}
          />
        </Switch>
      </main>
    </React.Fragment>
  );
};

export default Layout;
