import React from "react";
import Config from "./config";
import Amplify from "aws-amplify";
import {
  ForgotPassword,
  Loading,
  RequireNewPassword,
  SignIn,
  ConfirmSignUp,
  ConfirmSignIn,
  withAuthenticator
} from "aws-amplify-react";
import GetsbyAmplifyTheme from "./helpers/GetsbyAmplifyTheme";
import { ThemeProvider, createMuiTheme, CssBaseline } from "@material-ui/core";
import { getsbyYellow, getsbyGrey } from "./helpers/Color";
import Layout from "./containers/Layout";

Amplify.configure({
  Auth: {
    identityPoolId: Config.identityPoolId,
    region: Config.region,
    userPoolId: Config.userPoolId,
    userPoolWebClientId: Config.userPoolWebClientId,
    mandatorySignIn: true
  },
  API: {
    graphql_endpoint: Config.graphqlEndpoint,
    graphql_endpoint_iam_region: Config.region
  }
});

const getsbyTheme = createMuiTheme({
  palette: {
    primary: getsbyYellow,
    secondary: getsbyGrey
  }
});

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={getsbyTheme}>
        <CssBaseline />
        <Layout />
      </ThemeProvider>
    </div>
  );
};

export default withAuthenticator(
  App,
  false,
  [
    <SignIn />,
    <RequireNewPassword />,
    <ForgotPassword />,
    <Loading />,
    <ConfirmSignIn />,
    <ConfirmSignUp />
  ],
  null,
  GetsbyAmplifyTheme
);
