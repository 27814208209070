const envProd = {
  stage: "production",
  graphqlEndpoint: "https://v2.api.gets.by/graphql",
  region: "eu-central-1",
  identityPoolId: "eu-central-1:6c1bf450-cfad-4cdb-aa01-20bf80b9bd1a",
  userPoolId: "eu-central-1_ZRmQQzc4G",
  userPoolWebClientId: "g933okjpacji8u4k0fbbvcu1l",
  s3UploadBucket: "getsby-order-screen-production"
};

const envDev = {
  stage: "dev",
  graphqlEndpoint: "https://v2-dev.api.gets.by/graphql",
  region: "eu-central-1",
  identityPoolId: "eu-central-1:541ebaf6-bbd2-4383-81be-ff58904831a1",
  userPoolId: "eu-central-1_RETO432t8",
  userPoolWebClientId: "1ieqt87usmagjrgffia22dh2gd",
  s3UploadBucket: "getsby-order-screen-dev"
};

const envDemo = {
  stage: "demo",
  graphqlEndpoint: "https://v2-demo.api.gets.by/graphql",
  region: "eu-central-1",
  identityPoolId: "eu-central-1:dece8f0a-07f4-4135-bf56-ce8188b29494",
  userPoolId: "eu-central-1_6PvnyTilK",
  userPoolWebClientId: "6o4jm15jbpmk9bba7uge5no90s",
  s3UploadBucket: "getsby-order-screen-demo"
};

let Config = envDev;

switch (process.env.REACT_APP_ENV) {
  case "DEV":
    Config = envDev;
    break;
  case "DEMO":
    Config = envDemo;
    break;
  case "PROD":
    Config = envProd;
    break;
  default:
    Config = envDev;
}

export default Config;
