import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import {
  GroupedOrderItem,
  ExtendedReceipt,
  OrderItem,
  OrderItemModifierItem
} from "models";
import React from "react";

interface Props {
  receipt: ExtendedReceipt;
}

const ReceiptView = ({ receipt }: Props) => {
  const getGroupedOrders = (orderItems: OrderItem[]): GroupedOrderItem[] => {
    const groupedOrderItems: GroupedOrderItem[] = [];

    orderItems.forEach((orderItem: OrderItem) => {
      const existingItem = groupedOrderItems.filter((item) => {
        if (
          item.orderItems[0].product_uuid === orderItem.product_uuid &&
          JSON.stringify(item.orderItems[0].modifierItems) ===
            JSON.stringify(orderItem.modifierItems)
        ) {
          return true;
        }

        return false;
      });

      if (existingItem === null || existingItem.length === 0) {
        groupedOrderItems.push({
          amount: 1,
          title: orderItem.product_title,
          comment: orderItem.comment,
          productGroup: orderItem.productGroup,
          orderItems: [orderItem],
          modifierItems: orderItem.modifierItems || []
        });
      } else {
        const item = existingItem[0];
        item.amount++;
      }
    });
    return groupedOrderItems;
  };

  const groupedOrders =
    receipt && receipt.orderItems ? getGroupedOrders(receipt.orderItems) : [];

  return (
    <List dense>
      {groupedOrders.map((item: GroupedOrderItem, index: number) => {
        let itemPrimaryText = "";

        itemPrimaryText = itemPrimaryText.concat(
          item.amount.toString(),
          " x ",
          item.title,
          ` (${item.productGroup?.title})`
        );

        return (
          <ListItem key={index}>
            <ListItemText
              primary={
                <Typography variant="body1">{itemPrimaryText}</Typography>
              }
              secondary={
                item.modifierItems.length > 0 && (
                  <React.Fragment>
                    {item.comment && (
                      <Typography variant="body2">{item.comment}</Typography>
                    )}
                    <List dense>
                      {item.modifierItems.map(
                        (mi: OrderItemModifierItem, index: number) => (
                          <ListItem key={index}>
                            <Typography
                              variant="body2"
                              style={{ color: "red" }}
                            >
                              {mi.title}
                            </Typography>
                          </ListItem>
                        )
                      )}
                    </List>
                  </React.Fragment>
                )
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ReceiptView;
