import gql from "graphql-tag";

const gastronomyFragment = gql`
  fragment GastronomyFragment on Gastronomy {
    __typename
    uuid
    name
    orderScreenConfig {
      backgroundColor
      backgroundImageUrl
      acceptedCardColor
      acceptedFontColor
      readyCardColor
      readyFontColor
      appBarColor
      appBarFontColor
      headingColor
    }
  }
`;
const getGastronomy = gql`
  query getGastronomy($uuid: ID!) {
    getGastronomy(uuid: $uuid) {
      ...GastronomyFragment
    }
  }
  ${gastronomyFragment}
`;

export { getGastronomy };
