import React, { useEffect, useState } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  getExtendedReceiptsQuery,
  updateReceiptMutation
} from "../services/graphql/receipt";
import { GraphQLResult, ExtendedReceipt } from "models";
import { Grid, Typography, makeStyles, Theme } from "@material-ui/core";
import { getsbyYellow } from "../helpers/Color";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { isBefore, isAfter } from "date-fns";
import KitchenMonitorCard from "../components/KitchenMonitorCard";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    overflow: "scroll",
    height: "80vh",
    borderBottom: "2px dashed grey",
    alignItems: "flex-start",
    alignContent: "flex-start"
  },
  columnHeader: {
    textAlign: "center",
    borderRadius: "4px",
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  }
}));

interface Props {
  currentTime: number;
}

const KitchenMonitor = ({ currentTime }: Props) => {
  const classes = useStyles();
  const [receipts, setReceipts] = useState<ExtendedReceipt[]>([]);

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();

        const { data } = (await API.graphql(
          graphqlOperation(getExtendedReceiptsQuery, {
            params: {
              user_uuid: currentUser.getUsername(),
              status: ["ACCEPTED", "READY"],
              table_type: ["TAKEAWAY", "SELFSERVICE"]
            }
          })
        )) as GraphQLResult<{ getReceipts: ExtendedReceipt[] }>;

        if (!data) {
          throw new Error("API Fehler!");
        }

        console.log("data", data.getReceipts);

        setReceipts(data.getReceipts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReceipts();
  }, [currentTime]);

  const setReady = async (receipt: ExtendedReceipt) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      const { data } = (await API.graphql(
        graphqlOperation(updateReceiptMutation, {
          input: {
            user_uuid: currentUser.getUsername(),
            uuid: receipt.uuid,
            status: "READY"
          }
        })
      )) as GraphQLResult<{ adminUpdateReceipt: ExtendedReceipt }>;

      if (!data) {
        throw new Error("API Fehler!");
      }

      const newReceipt = data.adminUpdateReceipt;

      console.log("data", newReceipt);

      const newReceipts = receipts.map((r) => {
        if (r.uuid === receipt.uuid) {
          r.status = "READY";
        }

        return r;
      });

      setReceipts(newReceipts);
    } catch (error) {
      console.error(error);
    }
  };

  const filterAndSort = (
    receiptsToSort: ExtendedReceipt[],
    status: "ACCEPTED" | "READY"
  ): ExtendedReceipt[] => {
    return receiptsToSort
      .filter((r: ExtendedReceipt) => {
        if (r.status === status) {
          if (status === "ACCEPTED" && r.estimated_pickup_time) {
            return (
              differenceInMinutes(
                new Date(r.estimated_pickup_time),
                new Date()
              ) < 30
            );
          } else if (status === "ACCEPTED" && r.requested_pickup_time) {
            return (
              differenceInMinutes(
                new Date(r.requested_pickup_time),
                new Date()
              ) < 30
            );
          }

          return true;
        } else {
          return false;
        }
      })
      .sort((a: ExtendedReceipt, b: ExtendedReceipt) => {
        let dateA: Date = new Date(a.updated_at);
        let dateB: Date = new Date(b.updated_at);

        if (a.requested_pickup_time) {
          dateA = new Date(a.requested_pickup_time);
        } else if (a.estimated_pickup_time) {
          dateA = new Date(a.estimated_pickup_time);
        }

        if (b.requested_pickup_time) {
          dateB = new Date(b.requested_pickup_time);
        } else if (b.estimated_pickup_time) {
          dateB = new Date(b.estimated_pickup_time);
        }

        if (isBefore(dateA, dateB)) {
          return -1;
        }
        if (isAfter(dateA, dateB)) {
          return 1;
        }
        return 0;
      });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item sm={9}>
          <div
            className={classes.columnHeader}
            style={{
              backgroundColor: getsbyYellow[500]
            }}
          >
            <Typography variant="h3">In Bearbeitung</Typography>
          </div>
          <div className={classes.column}>
            {filterAndSort(receipts, "ACCEPTED").map((r: ExtendedReceipt) => (
              <KitchenMonitorCard receipt={r} key={r.uuid} onReady={setReady} />
            ))}
          </div>
        </Grid>
        <Grid item sm={3}>
          <div
            className={classes.columnHeader}
            style={{
              backgroundColor: "green",
              color: "white"
            }}
          >
            <Typography variant="h3">Abholbereit</Typography>
          </div>
          <div className={classes.column} style={{ justifyContent: "center" }}>
            {filterAndSort(receipts, "READY").map((r: ExtendedReceipt) => (
              <KitchenMonitorCard receipt={r} key={r.uuid} onReady={setReady} />
            ))}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default KitchenMonitor;
